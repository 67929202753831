import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

import {useState, useEffect} from 'react'
import { useNavigate } from "react-router-dom";
import { logout } from "../utils/Firebase";

function LeftMenu({user}) {

  const [open, setOpen] = useState(false)
  let navigate = useNavigate();

  const [loginMenuItem, setLoginMenuItem] = useState();
  const [userMenuItem, setUserMenuItem] = useState();
    useEffect(() => {

      if (user) {
        setLoginMenuItem(
          <MenuItem onClick={onLogout}>
            <ListItemText>Logout</ListItemText>
          </MenuItem>
        )
        setUserMenuItem(
          <MenuItem disabled>
            <ListItemText>{user.name}</ListItemText>
          </MenuItem>
        )
        } else {
        setLoginMenuItem( 
          <MenuItem onClick={onLogin}>
            <ListItemText>Login</ListItemText>
          </MenuItem>
        )
        setUserMenuItem()
      }
  
  }, [user])

  const toggleMenu = () => {
    // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //   return;
    // }
    setOpen(!open);
  };

  const onLogout = () => {
    setOpen(false);
    logout();
    navigate('/')
  }
  const onLogin = () => {
    setOpen(false);
    navigate('/login')
  }

  return (
    <div>
      <IconButton onClick={toggleMenu} >
        <MenuIcon color="secondary" />
      </IconButton>

      <Drawer
        anchor={'left'}
        open={open}
        onClose={toggleMenu}
      >
        <Paper sx={{ width: 320 }}>
          <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Freestyle Handshake
          </Typography>
          </Toolbar>
          <Divider />

          <MenuList>
          <MenuItem onClick={() => {navigate('/'); toggleMenu()}}>
              <ListItemText>Home</ListItemText>
            </MenuItem>

            <MenuItem onClick={() => {navigate('/events'); toggleMenu()}}>
              <ListItemText>Current Events</ListItemText>
            </MenuItem>

            <MenuItem onClick={() => {navigate('/allevents'); toggleMenu()}}>
              <ListItemText>All Events</ListItemText>
            </MenuItem>

            <Divider />

            {userMenuItem}
            {loginMenuItem}

          </MenuList>
        </Paper>
        <br/>
        <center><img src="/logo_150x150.png" width="150px" height="150px" /></center>
      </Drawer>
    </div>
  );
}
  
export default LeftMenu;