import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

// very simple page that shows a button to go to events
// really just here so every request to the app will not query list of events
function Home() {
    let navigate = useNavigate();

    return (
        <Box sx={{m:5}}>
            <h1>Buffalo Freestyle Handshake</h1>
            <p>Login to create events and upload results, or pick an event to view run orders and results.</p>
            <p><Button onClick={() => {navigate('/events')}} variant="contained">Go To Events</Button></p>
        </Box>
    )
  }
  
  export default Home;
