import {useState, useEffect, SyntheticEvent} from 'react'
import {useParams} from "react-router-dom";
import {collection, query, where, onSnapshot, getDoc, getDocs, doc, updateDoc, orderBy} from "firebase/firestore"

import {db} from '../utils/Firebase'
import UploadModal from '../components/UploadModal';
import EditEventModal from '../components/EditEventModal';

import { DataGrid, GridRowsProp, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import CheckIcon from '@mui/icons-material/Check';
import Checkbox from '@mui/material/Checkbox';
import CloseIcon from '@mui/icons-material/Close';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Select from '@mui/material/Select';

// show results for an event
// user and loading flag are passed in from App
function Results({user, loading}) {
  const params = useParams();       // pulls parameters from URL; this is where event id comes from
  const apiRef = useGridApiRef();
  const [event, setEvent] = useState({name:'',runs:[]})
  const [run, setRun] = useState(1)
  const [results, setResults] = useState([])
  const [isAdmin, setIsAdmin] = useState(false)
  const [groups, setGroups] = useState([])

  // on load, fetch the event
  useEffect(() => {
    if (loading) return;      // exit if still laoding

    // if we have an event id in URL, then get the event
    if (params.eventId) {
      getEvent(db, params.eventId, (err, evt) => {

        if (evt) {
          // let's see if current user is and admin of this event; if so, set isAdmin
          let name = null;
          if(evt?.admins && user?.email) {
            name = evt.admins.find((ad) => {
              return user.email.toLowerCase() == ad.toLowerCase()
            })
            if (name || user.super) {
              setIsAdmin(true)
            } else {
              setIsAdmin(false)
            }
          }
      
          setEvent(evt);    // set the event

          // check status field and parse out the run number
          if (evt.status.substring(0,3) == "Run") {
            setRun(Number(evt.status.substring(evt.status.indexOf(' ') + 1)))
          }
        }
      })  
    }
  }, [loading])

  // if we have an event, get the results
  useEffect(() => {
    if (loading) return;  // exit if still loading

    if (event.id) {
      // set grid column def based on event type
      setColDef(getGridCoumns(event.type))

      // get the results
      getResults(run);
    }

  }, [loading, event])


  // when results change
  const [nextUp, setNextUp] = useState()
  useEffect(() => {
    // ToDo: This will only work with DataGridPro
    //   apiRef.current.scrollToIndexes({
    //     rowIndex: results.length,
    //     colIndex: 0,
    //   })

    // find the next up athlete
    let next = results.find((row) => {
      return !row.started;
    })

    if (next) {
      setToastMessage("Next Up: " + next.Gp+" "+next.Last+", "+next.First+" ("+next.Bib+") "+next.Rep)
      setToastSeverity("info");
      setOpenToast(true)
      setNextUp(next)  
    }

    // make a list of the groups
    results.forEach((result) => {
      if (!groups.includes(result.Gp)) {
        groups.push(result.Gp)
      }
    })
    setGroups(groups)

  }, [results])

  useEffect(() => {
    groups.sort()
    setGroups(groups)
  }, [groups])

  // create the athlete name column
  const getAthlete = (params) => {
    return (
      <div>
        <div style={{lineHeight:"1"}}>{params.row.order}. <strong>{params.row.Last}, {params.row.First}</strong> ({params.row.bib})</div>
        <div style={{lineHeight:"1"}}>{params.row.Gp}, {params.row.Rep}</div>
      </div>
    )
  }

  // status check in first column
  const getStatus = (params) => {
    let started = params.row.started;
    let finished = params.row.finished;
    if (isAdmin) {
      return(
        <Checkbox
          value={params.row.id}
          checked={started}
          onChange={handleCheckChange}
          color={finished?"success":"warning"}
        />
      )
    } else {
      if (started) {
        if (finished) {
          return (<CheckIcon color="success"/>)
        } else {
          return (<CloseIcon color="warning"/>)
        }
      } else {
        return ("")
      }  
    }
  }

  // handle update of status
  const handleCheckChange = (e) => {

    let checked = e.target.checked
    let value = e.target.value

    if (value) {
      let docRef = doc(db, 'results', value);
      updateDoc(docRef, {
        started: checked,
        finished: checked,
      }).then((err) => {
      handleClose();
        }, (err) => {
          console.log(err)
          setToastMessage("Save Failed")
          setToastSeverity("error");
          setOpenToast(true)
      })
    }
  }

  // return the right grid columns based on event type
  const getGridCoumns = (type) => {
    if (type == "Aerial") {
      return airColumns
    } else if (type == "Slope") {
      return slopeColumns
    } else if (type == "Results") {
      return resultColumns
    } else {
      return mogulColumns
    }

  }

  // columns to display for moguls
  const mogulColumns: GridColDef[] = [
    { field: 'status', headerName:'', width: 15, renderCell: getStatus},
    { field: 'order', headerName: 'Order', width: 15 },
    // { field: 'No', headerName: 'No', width: 15 },
    { field: 'athlete', headerName: 'Athlete', width: 200, renderCell: getAthlete},
    { field: 'bib', headerName: 'Bib', width: 15 },
    { field: 'First', headerName: 'First Name', width: 150 },
    { field: 'Last', headerName: 'Last Name', width: 150 },
    { field: 'Rep', headerName: 'Team', width: 100 },
    { field: 'Gp', headerName: 'Group', width: 100 },
    { field: 'run', headerName: 'Run', width: 60 },
    { field: 'event', headerName: 'Event', width: 60 },
    { field: 'Time', headerName: 'Time', width: 60 },
    { field: 'J.1', headerName: 'J 1', width: 30 },
    { field: 'J.2', headerName: 'J 2', width: 30 },
    { field: 'J.3', headerName: 'J 3', width: 60 },
    { field: 'Jumps', headerName: 'Jumps', width: 60 },
    { field: 'DofD', headerName: 'DofD', width: 100 },
    { field: 'Judge', headerName: 'Judge', width: 80 },
    { field: 'Pts', headerName: 'Pts', width: 60 },
  ];

  // columns for an air comp
  const airColumns: GridColDef[] = [
    { field: 'status', headerName:'', width: 15, renderCell: getStatus},
    { field: 'order', headerName: 'Order', width: 15 },
    { field: 'athlete', headerName: 'Athlete', width: 200, renderCell: getAthlete},
    // { field: 'no', headerName: 'No', width: 15 },
    { field: 'bib', headerName: 'Bib', width: 15 },
    { field: 'First', headerName: 'First Name', width: 150 },
    { field: 'Last', headerName: 'Last Name', width: 150 },
    { field: 'run', headerName: 'Run', width: 60 },
    { field: 'event', headerName: 'Event', width: 60 },
    { field: 'Rep', headerName: 'Team', width: 100 },
    { field: 'Gp', headerName: 'Group', width: 100 },
    { field: 'J.1', headerName: 'J 1', width: 30 },
    { field: 'J.2', headerName: 'J 2', width: 30 },
    { field: 'J.3', headerName: 'J 3', width: 60 },
    { field: 'Judge', headerName: 'Judge', width: 80 },
    { field: 'Manuvr', headerName: 'Manuvr', width: 80 },
    { field: 'DofD', headerName: 'DofD', width: 100 },
  ];

  // columns for a slope comp
  const slopeColumns: GridColDef[] = [
    { field: 'status', headerName:'', width: 15, renderCell: getStatus},
    { field: 'order', headerName: 'Order', width: 15 },
    { field: 'athlete', headerName: 'Athlete', width: 200, renderCell: getAthlete},
    // { field: 'no', headerName: 'No', width: 15 },
    { field: 'bib', headerName: 'Bib', width: 15 },
    { field: 'First', headerName: 'First Name', width: 150 },
    { field: 'Last', headerName: 'Last Name', width: 150 },
    { field: 'run', headerName: 'Run', width: 60 },
    { field: 'event', headerName: 'Event', width: 60 },
    { field: 'Rep', headerName: 'Team', width: 100 },
    { field: 'Gp', headerName: 'Group', width: 100 },
    { field: 'J.1', headerName: 'J 1', width: 30 },
    { field: 'J.2', headerName: 'J 2', width: 30 },
  ];

  // for results, get the row index; the athlete position
  const [rowCount, setRowCount] = useState(1);
  const getRowIndex = (index) => {
    return index.api.getRowIndexRelativeToVisibleRows(index.row.id) + 1
  }
  // for results, get the athlete overall position
  const getOverallRowIndex = (index) => {
    return index.api.getRowIndex(index.row.id) + 1
  }

  // columns for results
  const resultColumns: GridColDef[] = [
    { 
      field: 'place' , 
      headerName: 'Place', 
      filterable: false,
      sortable: false,
      width: 15,
      renderCell: getRowIndex,
    },
   { 
      field: 'overall' , 
      headerName: 'Overall', 
      filterable: false,
      sortable: false,
      width: 15,
      renderCell: getOverallRowIndex,
  },
  { field: 'event', headerName: 'Score', width: 70 },
    { field: 'First', headerName: 'First Name', width: 150 },
    { field: 'Last', headerName: 'Last Name', width: 150 },
    { field: 'bib', headerName: 'Bib', width: 15 },
    { field: 'Rep', headerName: 'Team', width: 20 },
    { field: 'Gp', headerName: 'Group', width: 20 }
    // { field: 'gender', headerName: 'Gender', width: 15 }
  ]
  const [colDef, setColDef] = useState(mogulColumns)

  // what columns are visible by default
  const initialColumnVisibility = {
        order: false,
        bib: false,
        First: false,
        Last: false,
        Rep: false,
        Gp: false,
  }
  // visible columns for results
  const resultColumnVisibility = {
    order: false,
    bib: true,
    First: true,
    Last: true,
    Rep: true,
    Gp: true,
  }
  const [columnVisibility, setColumnVisibility] = useState(initialColumnVisibility)

  // get the event
  const getEvent = (db, eventId, cb) => {

    const docRef = doc(db, "events", eventId);
    const docSnap = getDoc(docRef).then((doc) => {

      cb(null, {
          id: doc.id,
          name: doc.data().name,
          type: doc.data().type,
          runs: doc.data().runs,
          date: doc.data().date,
          admins: doc.data().admins,
          status: doc.data().status,
          description: doc.data().description
      })
  
    });

  }

  // fetch the event results
  const getResults = (run) => {

    const q = query(collection(db, "results"), where("eventId", "==", event.id), where("eventRun", "==", run), orderBy("order", "asc"));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      setResults(querySnapshot.docs.map((doc) => {

        let row = doc.data();
        row['id'] = doc.id;
        return row;
      }))
    },
    error => {
      // if index is missing, error message should provide a link to generate required index.
      console.log(error);
    })

  }

  // handle tab change
  const handleTabChange = (e, newRun) => {
    setRun(newRun);

    if (newRun > event.runs) {
      getResults(event.runs);
      setColDef(getGridCoumns("Results"))
      setColumnVisibility(resultColumnVisibility)
      setSortModel(resultsSortModel)
    } else {
      setColDef(getGridCoumns(event.type))
      setColumnVisibility(initialColumnVisibility)
      getResults(newRun);
      setSortModel(defaltSortModel)
    }

    setFilterModel({
      items: [],
    })
    setFilterResultsBy("");

  };

  // setup the tabs for runs
	const getRunTabs = runs => {
    if (runs < 1) return ("")

		let tabs = [];
		for (let i = 1; i <= runs; i++) {
			tabs.push(<Tab key={i} label={"Run "+i} value={i} />);
		}

    return (
      <Tabs onChange={handleTabChange} value={run}>
      {tabs}
      <Tab key="results" label="Results" value={99} />
    </Tabs>

    )

	};

  // setup sub menu
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const clickSubMenu = (e, event) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // setup the edit event menu
  const secondaryAction = (event) => {
    if (isAdmin) {
      return (
        <IconButton
            id="basic-button"
            aria-controls={menuOpen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? 'true' : undefined}
            onClick={e => clickSubMenu(e, event)}
        >
          <MoreVertIcon />
        </IconButton>
             
      )
    }
  }

  // setup the edit event modal
  const [editModalOpen, setEditModalOpen] = useState(false)
  const openEdit = () => {
      setAnchorEl(null);
      setEditModalOpen(true);
  };

  // setup the upload modal
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const openUpload = () => {
      setAnchorEl(null);
      setUploadModalOpen(true);
  };

  // close any of the modals
  const closeModal = (value) => {
    setUploadModalOpen(false);
    setEditModalOpen(false);
  };

  // setup toast
	const [toastMessage, setToastMessage] = useState("");
	const [toastSeverity, setToastSeverity] = useState("success");
	const [openToast, setOpenToast] = useState(false);
	const handleToastClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
		setOpenToast(false);
	};

  // setup filter
  const [filterModel, setFilterModel] = useState({
    items: [],
  });

  const filterChanged = (e) => {
    let filter = e.target.value;
    setFilterResultsBy(filter);
    if (filter=="") {
      setFilterModel({
        items: [],
      })
    }else if (filter=="men") {
      setFilterModel({
        items: [
          { columnField: 'Gp', operatorValue: 'contains', value: 'm' }
        ],
      })
    } else if (filter=="women") {
      setFilterModel({
        items: [
          { columnField: 'Gp', operatorValue: 'contains', value: 'f' }
        ],
      })
    } else {
      setFilterModel({
        items: [
          { columnField: 'Gp', operatorValue: 'contains', value: filter }
        ],
      })
    }
  };

	const [filterResultsBy, setFilterResultsBy] = useState("");
  const filterSelect = () => {
    if (run <= event.runs) return;

    return (
      <Select
      sx={{ mt: 0, ml: 4 }}
      id="select-result-filter"
      value={filterResultsBy}
      onChange={filterChanged}
      displayEmpty
      label="Event Type">
          <MenuItem key="overall" value="">Overall</MenuItem>
          <MenuItem key= "men" value="men">Men</MenuItem>
          <MenuItem key="women" value="women">Women</MenuItem>
          {groups.map((group) => {
            return (<MenuItem key={group} value={group}>{group}</MenuItem>)
          })}
      </Select>
    )
  }

  // setup sorting
  const defaltSortModel = [
    { field: 'order', sort: 'asc' },
  ];
  const resultsSortModel = [
    { field: 'event', sort: 'desc' },
  ];
  const [sortModel, setSortModel] = useState(defaltSortModel);


  return (
    <div style={{ height: 600, width: '100%' }}>
      <Toolbar>

      <Breadcrumbs aria-label="breadcrumb" sx={{ flexGrow: 1 }}>
        <Link underline="hover" color="inherit" href="/events">
          Events
        </Link>

          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            {event.name}
          </Typography>
        </Breadcrumbs>

        {secondaryAction(event)}
      </Toolbar>

      <Toolbar>
        {getRunTabs(event.runs)}

        {filterSelect()}

      </Toolbar>

      <DataGrid 
        apiRef={apiRef}
        // initialState={initialGridState} 
        columnVisibilityModel={columnVisibility}
        filterModel={filterModel}
        sortModel={sortModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        rows={results} 
        columns={colDef}
        density="compact"
        // rowHeight={50}
        hideFooter 
        disableSelectionOnClick
        />

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
        <MenuItem onClick={openUpload}>Upload Results</MenuItem>
        <MenuItem onClick={openEdit}>Edit Event</MenuItem>
      </Menu>

      <EditEventModal open={editModalOpen} event={event} onClose={closeModal} />
      <UploadModal open={uploadModalOpen} event={event} onClose={closeModal} />

      <Snackbar
        open={openToast}
        autoHideDuration={4000}
        onClose={handleToastClose}>
        <Alert severity={toastSeverity} sx={{ width: '100%' }}>
          {toastMessage}
          </Alert>
      </Snackbar>

    </div>
  )


}



export default Results;
