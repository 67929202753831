import {useEffect, useState} from 'react'
import {collection, query, onSnapshot, where, orderBy} from "firebase/firestore"
import {db} from '../utils/Firebase'
import Toolbar from '@mui/material/Toolbar';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

// WORK ON PROGRESS... NOT SURE WHAT TO DO WITH IT

function Dashboard() {
  const [events, setEvents] = useState([])
  const [eventId, setEventId] = useState([])
  const [results, setResults] = useState([])

  // get the events
  useEffect(() => {
    let q = query(collection(db, "events"), where("status", "!=", "Finished"));

    onSnapshot(q, (querySnapshot) => {    //TODO: This doesn't have to update on snapshot; could just get events once
      setEvents(querySnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        type: doc.data().type,
        runs: doc.data().runs,
        date: doc.data().date,
        status: doc.data().status,
        admins: doc.data().admins,
        description: doc.data().description
      })))
    })
  }, [])

  // make menu items for event select
  const getEventMenuItems = (events) => {
		let items = [];
    events.forEach(ev => {  //TODO: This could use map
			items.push(<MenuItem key={ev.id} value={ev.id}>{ev.name}</MenuItem>);
    })
		return items;
	};

  // handle change of event dropdown
  const handleEventChange = (e) => {
		setEventId(e.target.value);
  }

  // handle event changing; get the event results
  useEffect(() => {
    let q = query(collection(db, "results"), where("eventId", "==", eventId), orderBy("order", "asc"));

    onSnapshot(q, (querySnapshot) => {    //TODO: This doesn't have to update on snapshot; could just get events once
      setResults(querySnapshot.docs.map((doc) => {
        let row = doc.data();
        row['id'] = doc.id;
        return row;
      }))
    })

  }, [eventId])

  // send output to browser
  return (
    <div>
      <Toolbar>

        <Breadcrumbs aria-label="breadcrumb" sx={{ flexGrow: 1 }}>
        <Link underline="hover" color="inherit" href="/">
            Home
        </Link>

            <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
              Dashboard
            </Typography>
        </Breadcrumbs>

      </Toolbar>

      <FormControl>
        <InputLabel id="label-id-event">Event</InputLabel>
        <Select
          sx={{ minWidth: 300 }}
          labelId="label-id-event"
          id="event-select"
          value={eventId}
          label="Event"
          onChange={handleEventChange}
        >
        	{getEventMenuItems(events)} 

        </Select>
      </FormControl>

    </div>
  )
    
}


export default Dashboard;
