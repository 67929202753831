import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

function FabCreateNew(props) {
  const { show, onClick } = props;

  if (show) {
    return (
    <Fab color="secondary" aria-label="add" sx={{
        position: "fixed",
        bottom: (theme) => theme.spacing(4),
        right: (theme) => theme.spacing(4)
      }}>
      <AddIcon onClick={onClick} />
    </Fab>
    )
  } else {
    return("");
  }
}
    
export default FabCreateNew;

