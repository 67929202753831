import UploadModal from '../components/UploadModal';
import EditEventModal from '../components/EditEventModal';
import DeleteEventModal from '../components/DeleteEventModal';
import FabCreateNew from '../components/FabCreateNew';

import {useEffect, useState} from 'react'
import { useNavigate } from "react-router-dom";

import {db} from '../utils/Firebase'
import {collection, query, onSnapshot, where} from "firebase/firestore"

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { pink } from '@mui/material/colors';

// display a list of all the events
function Events({user, showAll}) {

  const [events, setEvents] = useState([])                // all of the events
  const [currentEvent, setCurrentEvent] = useState({})    // the currently selected event
  const navigate = useNavigate();

  // when an event is clicked, navigate to the results page with the event id
  const changeEvent = (event) => {
    navigate('/results/' + event.id);
  }

  // setup the sub menu next to each event
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const clickSubMenu = (e, event) => {        // when menu clicked, set current event and open menu
    setCurrentEvent(event);
    setAnchorEl(e.currentTarget);             // menu will display if there is an anchor object set
  };
  const handleClose = () => {                 // close the menu; setAnchorEl to null
    setAnchorEl(null);
  };

  // setup the upload modal
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const openUpload = () => {
    setAnchorEl(null);              // close the menu
    setUploadModalOpen(true);       // open the pop-up
  };
  const closeModal = (value) => {
    setUploadModalOpen(false);
    setEditModalOpen(false);
    setDeleteModalOpen(false);
  };

  // setup the edit event modal
  const [editModalOpen, setEditModalOpen] = useState(false)
  const openEdit = () => {
      setAnchorEl(null);
      setEditModalOpen(true);
  };

  // open the create new modal
  const openNew = () => {
    setCurrentEvent({             // clear out the current event
      name: "",
      type: "Mogul",
      runs: 2,
      admins: [],
      status: "Planned",
      description: "",
      date: ""
    });
    setTimeout(() => {            // workaround; need to wait 500ms before opening
      setAnchorEl(null);
      setEditModalOpen(true);  
    }, 500);
  };

  // setup the delete modal event
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const openDelete = () => {
      setAnchorEl(null);
      setDeleteModalOpen(true);
  };

  // get the events
  useEffect(() => {
    let q;
    if (showAll) {
      // get all of the events
      q = query(collection(db, "events"));
    } else {
      // get only events that are not finished
      q = query(collection(db, "events"), where("status", "!=", "Finished"));
    }

    // on shapshot will get the events every time the list of events changes
    onSnapshot(q, (querySnapshot) => {
      // get the vents and map them; creating an event for each one
      let events = querySnapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data().name,
        type: doc.data().type,
        runs: doc.data().runs,
        date: doc.data().date,
        status: doc.data().status,
        admins: doc.data().admins,
        description: doc.data().description
      }));
      // set the events
      setEvents(events);
    })
  }, [showAll])
  
  // pop up menu next to the events; only if admin
  const secondaryAction = (event) => {
    let isAdmin = event.admins.find((ad) => {
      return user?.email.toLowerCase() == ad.toLowerCase()
    })
    if (isAdmin || user?.super) {
      return (
        <IconButton
            id="basic-button"
            aria-controls={menuOpen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? 'true' : undefined}
            onClick={e => clickSubMenu(e, event)}
        >
          <MoreVertIcon />
        </IconButton>
             
      )
    }
  }

  // if the event is active, show a chip
  const activeChip = (status) => {
    if (status != "Planned" && status != "Finished") {
      return (<Chip label="Live" color="success" sx={{ml:1,height:20}} />)
    }
  }

  return (
    <div>
      <Box sx={{ width: '100%'}}>
        <Toolbar>
          <Typography variant="h5" component="div" sx={{ flexGrow: 1 }}>
            {showAll?"All":"Planned & Current"} Events
          </Typography>
        </Toolbar>
        <List>
          {events.map((event) => (
            <ListItem 
              key={event.id}
              disablePadding
              secondaryAction={secondaryAction(event) }
            >
              <ListItemButton
                id={event.id} 
                key={event.id}
                onClick={() => changeEvent(event)}
              >
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: pink[500] }}>
                  {event.type}
                </Avatar>
              </ListItemAvatar>
                  <ListItemText secondary={event.description}>{event.name}
                  {/* TODO: Fix format of this date */}
                    <Chip label={event.date} sx={{ml:2,height:20}} />
                    {activeChip(event.status)}
                  </ListItemText>
              </ListItemButton>
            </ListItem>

            ))}

        </List>
      </Box>

      <FabCreateNew show={user} onClick={openNew} />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={openUpload}>Upload Results</MenuItem>
        <MenuItem onClick={openEdit}>Edit Event</MenuItem>
        <MenuItem onClick={openDelete}>Delete Event</MenuItem>
      </Menu>

      <EditEventModal open={editModalOpen} event={currentEvent} onClose={closeModal} user={user} />
      <DeleteEventModal open={deleteModalOpen} event={currentEvent} onClose={closeModal} /> 
      <UploadModal open={uploadModalOpen} event={currentEvent} onClose={closeModal} />

    </div>
  )
}
  
export default Events;
