import { Celebration } from "@mui/icons-material";
import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  signInAnonymously,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDQtFZANVuQLDTJyRRk3Dws0OIpOHIHpDk",
  authDomain: "freestyle-handshake.firebaseapp.com",
  projectId: "freestyle-handshake",
  storageBucket: "freestyle-handshake.appspot.com",
  messagingSenderId: "498093964114",
  appId: "1:498093964114:web:503619751400d1a713988b"
};

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const docs = await getDocs(q);
    if (docs.docs.length === 0) {
      await addDoc(collection(db, "users"), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

//TODO: Rewrite these to take a callback and display better error message
const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    await addDoc(collection(db, "users"), {
      uid: user.uid,
      name,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = (email, cb) => {
    sendPasswordResetEmail(auth, email).then(() => {
      cb(null)
    }).catch((err) => {
      cb(err)
    });
};

const logout = () => {
  signOut(auth);
};

const anonymousSignIn = (cb) => {
  signInAnonymously(auth).then(() => {
    cb()
  }).catch((err) => {
    console.log("Anonymous Signin ERROR", err)
    cb(err)
  })
}

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();


export {
  auth,
  db,
  signInWithGoogle,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  anonymousSignIn,
  sendPasswordReset,
  logout,
};