import {useState, useEffect} from 'react'
import {BrowserRouter,Routes, Route} from "react-router-dom";
import { query, collection, getDocs, where } from "firebase/firestore";
import { useAuthState } from "react-firebase-hooks/auth";

import { auth, db, anonymousSignIn, logout } from "./utils/Firebase";
import Header from './components/Header';
import Login from './components/Login';
import Register from './components/Register';
import Reset from './components/Reset';
import Dashboard from './view/Dashboard';
import Home from './view/Home';
import Events from './view/Events';
import Results from './view/Results';
import PageNotFound from './PageNotFound';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

// setup the UI theme (colors, fonts, etc) used by @mui
export const theme = createTheme( {
  palette: {
    type: 'light',
    primary: {
      main: '#000000',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#f50057',
    },
  },
});

function App() {

  const [authUser, authLoading, error] = useAuthState(auth);  // authUser is created and manged by Firebase
  const [user, setUser] = useState(null);                     // user is an object we created to store user name
  const [loading, setLoading] = useState(true);               // flag to track page loading (true while loading, false when done)

  // function to query firebase and get the current user's name; ut it into user; if logged in
  const fetchUserName = async (cb) => {
    try {
      const q = query(collection(db, "users"), where("uid", "==", authUser?.uid));
      const doc = await getDocs(q);
      const data = doc.docs[0].data();
      setUser({
        id: authUser.uid,
        name: data.name,
        email: data.email,
        super: data.super
      });
      cb()
    } catch (err) {
      console.error(err);
      cb(err);
    }
  };

  // when the authUser changes or authLoading changes (authLoading changes when authentication is done)
  useEffect(() => {
    if (authLoading) return;          // quit if still loading
    if (authUser) {
      if (authUser.isAnonymous) {     // got authUser, but he is anyonymous (not logged in)
        setUser(null);
        setLoading(false);  
      } else {
        fetchUserName((err) => {      // got authUser and not anonymous, so go get his name
          if(err) return;
          setLoading(false);
        });  
      }
    } else {
      anonymousSignIn((err) => {      // there is no authUser, so log in as anonymous
        setUser(null);
        setLoading(false);  
      })
    }
  }, [authUser, authLoading]);        // this function runs when authUser or authLoading changes

  // Send highest level DOM to browser; mostly a box, with header and content
  // Routes will pick which view to send based on URL
  return (
    <ThemeProvider theme={theme}>
    <Box sx={{ flexGrow: 1 }}>
      <BrowserRouter>
        <Header user={user} />
        <div className="container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/events" element={<Events user={user} />} />
            <Route path="/allevents" element={<Events user={user} showAll={true} />} />
            <Route path="/results/:eventId" element={<Results user={user} loading={loading} />} />
            <Route exact path="/login" element={<Login />} />
            <Route exact path="/register" element={<Register />} />
            <Route exact path="/reset" element={<Reset />} />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </div>
      </BrowserRouter>
    </Box>  
    </ThemeProvider>
  )

}

export default App;
