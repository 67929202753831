import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { auth, sendPasswordReset } from "../utils/Firebase";
import Snackbar from '@mui/material/Snackbar';
import "./Reset.css";
import Alert from '@mui/material/Alert';

function Reset() {
  const [email, setEmail] = useState("");
  const [authUser, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) return;
    if (authUser) {
      if (!authUser.isAnonymous) {
        navigate("/");
      }
    }  
  }, [authUser, loading]);

  const sendReset = () => {
    sendPasswordReset(email, (err) => {
      if (err) {
        setToastMessage("Password reset failed")
        setToastSeverity("warning");
        setOpenToast(true)
        console.log("Error resetting password", err)
      } else {
        setEmail("")
        setToastMessage("Password reset sent")
        setToastSeverity("success");
        setOpenToast(true)
        console.log("Password reset")
      }
    })
  }

  // setup toast pop-up
	const [toastMessage, setToastMessage] = useState("");
	const [toastSeverity, setToastSeverity] = useState("success");
	const [openToast, setOpenToast] = useState(false);
	const handleToastClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
		setOpenToast(false);
	};


  return (
    <div className="reset">
      <div className="reset__container">
        <input
          type="text"
          className="reset__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <button
          className="reset__btn"
          onClick={sendReset}
        >
          Send password reset email
        </button>
        <div>
          Don't have an account? <Link to="/register">Register</Link> now.
        </div>
      </div>
      <Snackbar
			open={openToast}
			autoHideDuration={4000}
			onClose={handleToastClose}>
      //TODO: Move this toast message to a component
			<Alert severity={toastSeverity} sx={{ width: '100%' }}>
				{toastMessage}
  			</Alert>
		</Snackbar>    </div>
  );
}

export default Reset;