import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useState} from 'react'
import {db} from '../utils/Firebase'
import { writeBatch, doc, collection, getDocs, query, where, deleteDoc} from "firebase/firestore"; 
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import asyncEach from 'async/each';

function DeleteEventModal(props) {
	const { onClose, open, event } = props;

	const handleClose = () => {
		onClose();
	};

	const [toastMessage, setToastMessage] = useState("");
	const [toastSeverity, setToastSeverity] = useState("success");
	const [openToast, setOpenToast] = useState(false);
	const handleToastClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
		setOpenToast(false);
	};

  const deleteEvent = () => {

		const batch = writeBatch(db);

		const q = query(collection(db, "results"), where("eventId", "==", event.id));
		getDocs(q).then((querySnapshot) => {
      asyncEach(querySnapshot.docs, (deleteDoc, cb) => {
        try {
          batch.delete(deleteDoc.ref);
          cb(null);
        } catch (e) {
          console.log("Firestore delete error: ", e)
          cb(e);	
        }
      }, (err) => {
        if (err) {
          console.log("Unable to delete results:", err)
          setToastMessage("Delete Failed")
          setToastSeverity("error");
          setOpenToast(true)
        } else {
					// Commit the batch
					batch.commit().then(() => {

            const docRef = doc(db, 'events', event.id);
            deleteDoc(docRef).then((err) => {
                setToastMessage("Event deleted")
                setToastSeverity("success");
                setOpenToast(true)
                handleClose();
              }, (err) => {
                console.log("BATCH EVENT DELETE ERROR: ", err);
                setToastMessage("Save Failed")
                setToastSeverity("error");
                setOpenToast(true)
            })

					}).catch((error) => {
						console.log("BATCH EVENT DELETE ERROR: ", error);
						setToastMessage("Delete Events Failed")
						setToastSeverity("error");
						setOpenToast(true)
					})  
        }
      })  
    });

  }

  return (
    <div>
      <Dialog onClose={handleClose} open={open} >
        <DialogTitle>Delete Event</DialogTitle>
          <DialogContent>
        	<DialogContentText>
            Are you 100% sure you want to delete event: <b>{event.name}</b>? Any results will also be deleted, and this cannot be undone.
          </DialogContentText>

			</DialogContent>
          <DialogActions>
            <Button key="b1" onClick={handleClose}>Cancel</Button>
            <Button key="b2" onClick={deleteEvent}>Delete Event</Button>
          </DialogActions>
      </Dialog>
      	<Snackbar
			open={openToast}
			autoHideDuration={4000}
			onClose={handleToastClose}>
			<Alert severity={toastSeverity} sx={{ width: '100%' }}>
				{toastMessage}
  			</Alert>
		</Snackbar>
    </div>
  );
}
  
export default DeleteEventModal;