import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import LeftMenu from './LeftMenu';

function Header({user}) {
  let navigate = useNavigate();

  return (
      <AppBar position="static">
      <Toolbar>
        <LeftMenu user={user} />
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Freestyle Handshake
        </Typography>
      </Toolbar>
    </AppBar>
  );
}
  
  export default Header;
