import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Input from '@mui/material/Input';
import Papa from "papaparse";
import {useEffect, useState} from 'react'
import {db} from '../utils/Firebase'
import { writeBatch, doc, collection, getDocs, query, where } from "firebase/firestore"; 
import asyncEach from 'async/each';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import asyncMap from 'async/map';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Checkbox from '@mui/material/Checkbox';

function UploadModal(props) {

	const [run, setRun] = useState(0);
	const { onClose, open } = props;
	const event = props.event;

	const handleClose = () => {
		onClose();
	};
	const [toastMessage, setToastMessage] = useState("");
	const [toastSeverity, setToastSeverity] = useState("success");
	const [openToast, setOpenToast] = useState(false);
	const handleToastClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}

		setOpenToast(false);
	  };

	// Allowed extensions for input file
	const allowedExtensions = ["csv"];

	// This state will store the parsed data
	const [data, setData] = useState([]);
	
	// It state will contain the error when
	// correct file extension is not used
	const [error, setError] = useState("");
		
	// It will store the file uploaded by the user
	const [file, setFile] = useState("");

	// This function will be called when
	// the file input changes
	const handleFileChange = (e) => {
			setError("");
				
			// Check if user has entered the file
			if (e.target.files.length) {
					const inputFile = e.target.files[0];
						
					// Check the file extensions, if it not
					// included in the allowed extensions
					// we show the error
					const fileExtension = inputFile?.type.split("/")[1];
					if (!allowedExtensions.includes(fileExtension)) {
							setError("Please input a csv file");
							return;
					}

					// If input type is correct set the state
					setFile(inputFile);
			}
	};

	// handle flag to replace eixisting data
	const [replaceExisting, setReplaceExisting] = useState(false);
	const replaceExistingChanged = () => {
		setReplaceExisting(!replaceExisting);
	}

	const pareseData = () => {
				
		// If user clicks the parse button without
		// a file we show a error
		if (!file) return setError("Enter a valid file");

		// Initialize a reader which allows user
		// to read any file or blob.
		const reader = new FileReader();
			
		// Event listener on reader when the file
		// loads, we parse it and set the data.
		reader.onload = async ({ target }) => {

			const csv = Papa.parse(
				target.result, 
				{ 
					header: true, 
					skipEmptyLines: true,
					transformHeader: (value, idx) => {
						if (value=="") {
							return "blank"
						} else {
							return value
						}
					}
				}
				);

			const parsedData = csv.data.map((row, idx) => {
				row['eventId'] = event.id;
				row['eventRun'] = Number(run);
				row['order'] = idx+1;

				if (row.Run) {
					if (row.Run=="dns" && replaceExisting) {
						row['started'] = false;
						row['finished'] = false;
					} else if (row.Run=="dnf") {
						row['status'] = row.Run;
						row['started'] = true;
						row['finished'] = false;
					} else if (Number(row.Run)>0) {
						row['run'] = Number(row.Run);
						row['started'] = true;
						row['finished'] = true;
					}
				} 

				if (row.Pts) {
					row['pts'] = Number(row.Pts);
				}
				if (row.No) {
					row['no'] = Number(row.No);
				}
				if (row.Judge) {
					row['judge'] = Number(row.Judge);
				}
				if (row.Event) {
					row['event'] = Number(row.Event);
				}
				if (row.Bib) {
					row['bib'] = Number(row.Bib);
				}
				if (row.Gp) {
					let gender = row.Gp.substring(0, 1);
					row['gender'] = gender.toLowerCase();
				}
				return row;
			});

			uploadResults(parsedData)
		};
		reader.readAsText(file);
	};


	const handleRadioChange = (e) => {
		setRun(Number(e.target.value));
	  };

	const uploadResults = async (data) => {

		// Get a new write batch
		const batch = writeBatch(db);

		// find the old results and remove them
		const q = query(collection(db, "results"), where("eventId", "==", event.id), where("eventRun", "==", run));
		const querySnapshot = await getDocs(q);
		asyncEach(querySnapshot.docs, (deleteDoc, cb) => {
			// delete if replace existing is checked
			if (replaceExisting) {
				try {
					batch.delete(deleteDoc.ref);
					cb(null);
				} catch (e) {
					console.log("Firestore delete error: ", e)
					cb(e);	
				}	
			} else {
				cb(null);
			}
		}, (err) => {
			if(err) {
				console.log("ERROR", err)
				setToastMessage("Upload Failed (0)")
				setToastSeverity("error");
				setOpenToast(true);
				return;
			}

			// loop through and create new result docs in batch
			asyncEach(data, (row, cb) => {
				try {
					// console.log(row)

					// const newDoc = doc(collection(db, "results"));
					// batch.set(newDoc, row);

					let docid = row.eventId + "-" + row.eventRun + "-" + row.bib;
					const newDoc = doc(db, "results", docid);
					batch.set(newDoc, row, {merge: true});

					cb(null);	
				} catch (e) {
					console.log("Firestore set error: ", e)
					cb(e);	
				}
			}, (err) => {
				if(err) {
					console.log("ERROR", err)
					setToastMessage("Upload Failed (1)")
					setToastSeverity("error");
					setOpenToast(true)
				} else {
					// Commit the batch
					batch.commit().then(() => {
						// console.log("update worked")
						setReplaceExisting(false);
						setToastMessage("Results uploaded successfully")
						setToastSeverity("success");
						setOpenToast(true)
						handleClose()
					}).catch((error) => {
						console.log("BATCH ERROR: ", error);
						setToastMessage("Upload Failed (2)")
						setToastSeverity("error");
						setOpenToast(true)
					})
				}
			})
		})

	}

	const getRunControls = runs => {
		let radios = [];
		for (let i = 1; i <= runs; i++) {
			radios.push(<FormControlLabel key={'radio_'+i} value={i} control={<Radio />} label={"Run " + i} />);
		}
		return radios;
	};

  return (
    <div>
      <Dialog onClose={handleClose} open={open} >
        <DialogTitle>Upload Run Order and Results</DialogTitle>
          <DialogContent>
        	<DialogContentText>
            Upload run order and results into event <b>{event.name}</b>.
            </DialogContentText>
			<FormControl margin="normal" >

				<FormLabel>Load Into Run</FormLabel>
				<RadioGroup
					row={true} 
					name="run"
					value={run}
					key={run}
					onChange={handleRadioChange}>

					{getRunControls(event.runs)} 

				</RadioGroup>

				<FormLabel>File to upload</FormLabel>
				<Input
					onChange={handleFileChange}
					id="csvInput"
					name="file"
					type="File"
				/>
				
				<FormControlLabel control={<Checkbox onChange={replaceExistingChanged} checked={replaceExisting} />} label="Replace existing data" />

			</FormControl>
			</DialogContent>
          <DialogActions>
            <Button key="b1" onClick={handleClose}>Cancel</Button>
            <Button key="b2" onClick={pareseData}>Upload Results</Button>
          </DialogActions>
      </Dialog>
      	<Snackbar
			open={openToast}
			autoHideDuration={4000}
			onClose={handleToastClose}>
			<Alert severity={toastSeverity} sx={{ width: '100%' }}>
				{toastMessage}
  			</Alert>
		</Snackbar>
    </div>
  );
}
  
export default UploadModal;
