import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useState, useEffect} from 'react'
import {db} from '../utils/Firebase'
import { updateDoc, doc, addDoc, collection} from "firebase/firestore"; 
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Input from '@mui/material/Input';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import { MuiChipsInput } from 'mui-chips-input'

function EditEventModal(props) {
	const { onClose, open, user } = props;
  const [admins, setAdmins] = useState([])
  const [event, setEvent] = useState({})
  const [eventName, setEventName] = useState("")
  const [eventDesc, setEventDesc] = useState("")
  const [eventDate, setEventDate] = useState("")
  const [type, setType] = useState("Mogul")
  const [status, setStatus] = useState("Planned")
  const [runs, setRuns] = useState(2)

  useEffect(() => {
    setEvent(props.event)
    setEventName(props.event.name)
    setEventDesc(props.event.description)
    setEventDate(props.event.date)
    setStatus(props.event.status)
    setType(props.event.type)
    setRuns(props.event.runs)
  }, [props.event])

  useEffect(() => {
    if (event.admins && event.admins.length > 0) {
      setAdmins(event.admins)
    } else {
      setAdmins([user?.email])
    }
  }, [event])

	const handleClose = () => {
		onClose();
	};

  // setup and handle admin chips
  // const [admins, setAdmins] = React.useState([])
  const handleAdminChange = (newAdmins) => {
    // event.admins = newAdmins
    setAdmins(newAdmins)
  }

  // setup toast pop-up
	const [toastMessage, setToastMessage] = useState("");
	const [toastSeverity, setToastSeverity] = useState("success");
	const [openToast, setOpenToast] = useState(false);
	const handleToastClose = (event, reason) => {
		if (reason === 'clickaway') {
		  return;
		}
		setOpenToast(false);
	};

  // save the event
  const saveEvent = () => {
    let docRef = null;
    if (event.id) {
      docRef = doc(db, 'events', event.id);
      updateDoc(docRef, {
        name: eventName,
        type: type,
        status: status,
        runs: Number(runs),
        description: eventDesc,
        date: eventDate,
        admins: admins
      }).then((err) => {
          setToastMessage("Event updated successfully")
          setToastSeverity("success");
          setOpenToast(true)
      handleClose();
        }, (err) => {
          console.log(err)
          setToastMessage("Save Failed")
          setToastSeverity("error");
          setOpenToast(true)
      })
    } else {
      addDoc(collection(db, "events"), {
        name: eventName,
        type: type,
        status: status,
        runs: Number(runs),
        description: eventDesc,
        date: eventDate,
        admins: admins
      }).then((err) => {
          setToastMessage("Added new event successfully")
          setToastSeverity("success");
          setOpenToast(true)
      handleClose();
        }, (err) => {
          console.log(err)
          setToastMessage("Add Failed")
          setToastSeverity("error");
          setOpenToast(true)
      })
    }


  }

  // make run status selects
  const makeRunItems = () => {
    return (
      <MenuItem value="Run 1">Run 1</MenuItem>
    )
  }
  const getRunItems = () => {
		let radios = [];
		for (let i = 1; i <= runs; i++) {
			radios.push(<MenuItem key={"run-"+i} value={"Run "+i}>{"Run "+i}</MenuItem>);
		}
		return radios;
	};

  return (
    <div>
      <Dialog onClose={handleClose} open={open} maxWidth="md" fullWidth>
        <DialogTitle>{event?.id ? "Edit" : "Add New"} Event</DialogTitle>
          <DialogContent>
        	  <DialogContentText>
            </DialogContentText>
            <FormGroup >
              <InputLabel id="label-event-name">
                      Event Title
                  </InputLabel>
              <TextField
                  required
                  id="name"
                  defaultValue={event?.name}
                  onChange={(e) => {setEventName(e.target.value)}}
                  />
            </FormGroup>

            <FormGroup row>

            <FormGroup sx={{ mt: 1 }}>
                <InputLabel id="label-event-status">
                    Status
                </InputLabel>
                <Select
                labelId="label-event-status"
                id="select-event-status"
                value={status}
                onChange={(e) => {setStatus(e.target.value)}}
                autoWidth
                label="Event Type">
                    <MenuItem value="Planned">Planned</MenuItem>
                    {getRunItems(event.runs)}
                    <MenuItem value="Finished">Finished</MenuItem>
                </Select>
              </FormGroup>

              <FormGroup sx={{ mt: 1, ml: 4 }}>
                <InputLabel id="label-event-type">
                    Type
                </InputLabel>
                <Select
                labelId="label-event-type"
                id="select-event-type"
                value={type}
                onChange={(e) => {setType(e.target.value)}}
                autoWidth
                label="Event Type">
                    <MenuItem value="Mogul">Mogul</MenuItem>
                    <MenuItem value="Aerial">Aerial</MenuItem>
                    <MenuItem value="Slope">Slope</MenuItem>
                </Select>
              </FormGroup>

              <FormGroup  sx={{ mt: 1, ml: 4 }}>
                <InputLabel id="label-event-date">
                        Date
                    </InputLabel>
                <TextField
                    required
                    id="date"
                    defaultValue={event?.date}
                    onChange={(e) => {setEventDate(e.target.value)}}
                    type="date"
                    />
              </FormGroup>

              <FormGroup sx={{ mt: 1, ml: 4 }}>
                <InputLabel id="label-event-runs">
                    Number of Runs
                </InputLabel>
                <Input 
                  defaultValue={runs}
                  onChange={(e) => {setRuns(e.target.value)}}
                  type="number" 
                  min="1" 
                  max="6" 
                  step="1" />
              </FormGroup>

            </FormGroup>

            <FormGroup sx={{ mt: 1}}>
              <InputLabel id="label-event-name">
                      Description
                  </InputLabel>
              <TextField
                  id="description"
                  defaultValue={event?.description}
                  onChange={(e) => {setEventDesc(e.target.value)}}
                  
                  />
            </FormGroup>


            <FormGroup sx={{ mt: 1}}>
              <InputLabel id="label-event-admins">
                    Administrators (enter email addresses)
              </InputLabel>
            <MuiChipsInput value={admins} onChange={handleAdminChange} />
            </FormGroup>

			    </DialogContent>

          <DialogActions>
            <Button key="b1" onClick={handleClose}>Cancel</Button>
            <Button key="b2" onClick={saveEvent}>Save Event</Button>
          </DialogActions>
        </Dialog>

      	<Snackbar
			open={openToast}
			autoHideDuration={4000}
			onClose={handleToastClose}>
			<Alert severity={toastSeverity} sx={{ width: '100%' }}>
				{toastMessage}
  			</Alert>
		</Snackbar>
    </div>
  );
}
  
export default EditEventModal;